import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectGender = createSelector(selectOnboarding, (onboarding) => onboarding.gender);
const selectAge = createSelector(selectOnboarding, (onboarding) => onboarding.age);
const selectCurrentWeight = createSelector(selectOnboarding, (onboarding) => onboarding.current_weight);
const selectTargetWeight = createSelector(selectOnboarding, (onboarding) => onboarding.target_weight);
const selectGoal = createSelector(selectOnboarding, (onboarding) => onboarding.goal);
const selectMeasureSystem = createSelector(selectOnboarding, (onboarding) => onboarding.measureSystem);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectTall = createSelector(selectOnboarding, (onboarding) => onboarding.tall);
const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectAgeRange = createSelector(selectOnboarding, (onboarding) => onboarding.age_range);
const selectStressFactors = createSelector(selectOnboarding, (onboarding) => onboarding.stress_factors);
const selectSleepProblems = createSelector(selectOnboarding, (onboarding) => onboarding.sleep_problems);
const selectCurrentMood = createSelector(selectOnboarding, (onboarding) => onboarding.current_mood);
const selectActivityLevel = createSelector(selectOnboarding, (onboarding) => onboarding.activityLevel);
const selectCurrentBody = createSelector(selectOnboarding, (onboarding) => onboarding.current_body);
const selectOccasionResultConfig = createSelector(selectOnboarding, (onboarding) => onboarding.occasionResultConfig);
const selectIsDiscountScratchingFinished = createSelector(
    selectOnboarding,
    (onboarding) => onboarding.isDiscountScratchingFinished
);

export {
    selectOnboarding,
    selectGender,
    selectCurrentWeight,
    selectTargetWeight,
    selectAge,
    selectGoal,
    selectMeasureSystem,
    selectOnboardingScreensList,
    selectTall,
    selectUrlParams,
    selectAgeRange,
    selectStressFactors,
    selectSleepProblems,
    selectCurrentMood,
    selectActivityLevel,
    selectCurrentBody,
    selectOccasionResultConfig,
    selectIsDiscountScratchingFinished,
};
